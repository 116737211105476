.login{
    display: flex;
    height: 100vh;
    &-form{
        display: flex;
        flex-direction: column;  
        
        width: 45%;
        padding: 0 10%;
        justify-content: center;
        gap: 1.5em;
        h3{
            color: #0B60B0;
            font-size: 2.5em;
            font-weight: 700;
            line-height: .9;
            small{
                font-size: 65%;
                font-weight: 500;
            }
        }
        &-error{
            color: red;
            font-size: 12px;
        }
        input{
            border-color: #0B60B0;
            border-radius: 1.5em;
        }
        label{
            color: #0B60B0;
            font-weight: 500;
        }


    }
    &-info{
        height: 100%;
        background-color: #0B60B0;
        padding: 5%;
        display: flex;
        gap: 1em;
        flex-direction: column;
        width: 55%;
        border-radius: 5% 0 0 5%;
        justify-content: center;
        h2,h1,h3,strong,p{
            color: white;
        }
        h1{
            font-size: 7em;
            z-index: 1;
            transform: translateY(30%);
        }
        svg,g,img{
            
            max-height: 50vh;
            object-fit: contain;
            z-index: 2;
        }
        
    }

    &-pass{
        display: flex;
        flex-direction: column;
        gap: .5em;
        span{
            display: flex;
            gap: .5em;
            width: 100%;
            justify-content: flex-end;
        }
    }

}