.navbar{
    background-color: #f2f2f28d;
    width: 100%;
    padding: 2em 5% !important;
    h5{
        color: #0A60B0;
        margin-bottom: 0;
    }
    &-buttons{
        display: flex;
        gap: .5em;
        align-items: center;
        position: relative;
       
    }
    &-panel{
        display: flex;
        gap: 2em;
        align-items: center;
        .c-select{
            background-color: white;
            
            border-radius: 2em;
            padding: .2em 1em;
            display: flex;
            select{
                color: #0A60B0;
                border: 1px solid transparent;
                font-weight: bold;
            }
            a{
                width: 30px;
                height: 30px;
                display: flex;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20.777' viewBox='0 0 20 20.777'%3E%3Cpath id='cog' d='M36.772,20.2l-1.366-.789a8.176,8.176,0,0,0,0-2.048l1.366-.789a1,1,0,0,0,.463-1.152,10.371,10.371,0,0,0-2.41-4.172,1.006,1.006,0,0,0-1.233-.179l-1.364.787a8.132,8.132,0,0,0-1.774-1.025V9.259a1.006,1.006,0,0,0-.774-.979,10.462,10.462,0,0,0-4.808,0,1.006,1.006,0,0,0-.774.979v1.576a8.131,8.131,0,0,0-1.774,1.025l-1.364-.787a1.006,1.006,0,0,0-1.233.179,10.371,10.371,0,0,0-2.41,4.172,1,1,0,0,0,.463,1.152l1.366.789a8.176,8.176,0,0,0,0,2.048L17.78,20.2a1,1,0,0,0-.463,1.152,10.371,10.371,0,0,0,2.41,4.172,1.006,1.006,0,0,0,1.233.179l1.364-.787A8.13,8.13,0,0,0,24.1,25.943v1.576a1.006,1.006,0,0,0,.774.979,10.462,10.462,0,0,0,4.808,0,1.006,1.006,0,0,0,.774-.979V25.943a8.132,8.132,0,0,0,1.774-1.025l1.364.787a1.006,1.006,0,0,0,1.233-.179,10.371,10.371,0,0,0,2.41-4.172,1,1,0,0,0-.463-1.152Zm-2.743,4.208-1.94-1.12a7.238,7.238,0,0,1-2.976,1.72v2.24a9.1,9.1,0,0,1-3.675,0v-2.24a7.239,7.239,0,0,1-2.976-1.72l-1.94,1.12a9.048,9.048,0,0,1-1.84-3.182l1.941-1.12a7.3,7.3,0,0,1,0-3.438l-1.941-1.12a9.055,9.055,0,0,1,1.84-3.182l1.94,1.121a7.227,7.227,0,0,1,2.976-1.72V9.527a9.081,9.081,0,0,1,3.675,0v2.24a7.227,7.227,0,0,1,2.976,1.72l1.94-1.121a9.054,9.054,0,0,1,1.84,3.182l-1.941,1.12a7.3,7.3,0,0,1,0,3.438l1.941,1.12a9.048,9.048,0,0,1-1.84,3.182ZM27.276,14.367A4.021,4.021,0,1,0,31.3,18.388,4.026,4.026,0,0,0,27.276,14.367Zm0,6.7a2.681,2.681,0,1,1,2.681-2.681A2.684,2.684,0,0,1,27.276,21.069Z' transform='translate(-17.276 -8)' fill='%230a60b0'/%3E%3C/svg%3E%0A");
                background-size: 50%;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    &-signout{
        width: 40px;
        height: 40px;
        border: none;
        background-color: transparent;
        background-size: 60%;
        background-color: white;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        transition: all ease-in .2s;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.5' height='27.907' viewBox='0 0 23.5 27.907'%3E%3Cg id='user-profile_14660' transform='translate(-1.559)'%3E%3Cpath id='Trazado_415' data-name='Trazado 415' d='M25.059,23.1H22.231a8.8,8.8,0,0,0-8.923-8.65A8.8,8.8,0,0,0,4.387,23.1H1.559A11.629,11.629,0,0,1,13.31,11.62,11.629,11.629,0,0,1,25.059,23.1Z' transform='translate(0 4.809)' fill='%230a60b0'/%3E%3Cpath id='Trazado_416' data-name='Trazado 416' d='M12.14,15.51A7.755,7.755,0,1,1,19.9,7.755,7.765,7.765,0,0,1,12.14,15.51Zm0-12.682a4.927,4.927,0,1,0,4.929,4.927A4.932,4.932,0,0,0,12.14,2.828Z' transform='translate(1.17)' fill='%230a60b0'/%3E%3C/g%3E%3C/svg%3E%0A");
        &:hover{
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cg id='Interface / Exit'%3E%3Cpath id='Vector' d='M12 15L15 12M15 12L12 9M15 12H4M4 7.24802V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2839 4.21799 18.9076C4 18.4798 4 17.9201 4 16.8V16.75' stroke='%230A60B0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

        }
    }
}