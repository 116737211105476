div{

}
.reset-password {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    padding: 1em 5%;
    gap: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    form{
        max-width: 400px;
        padding: 1em;
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 5px;

        button {
            width: 100%;
            padding: 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
    
            &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
            }
        }
    }

    h2 {
        text-align: center;
    }

    label {
        display: block;
        margin-bottom: 5px;
    }

    input {
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .error-message {
        color: red;
        margin-bottom: 10px;
    }

   
}