.collaborators{
    background-color: #ECECEC;
    margin-bottom: 2em;
    &-row{
        display: flex;
    }
    &-wrapper{
        border-radius: 5;
        padding: 2em 5%;
        display: flex;
        background-color: white;
        border-radius: 4em;
        min-width: 85%;
        flex-direction: column;
        gap: 1.5em;

        form{
            display: flex;
            flex-direction: column;
            gap: .5em;
            input.-disable{
                cursor: not-allowed;
                opacity: .5;
            }
            .c-input{
                display: flex;
                flex-direction: column;
                
            }
            input, select{
                border-radius: .5em;
                padding: .3em .5em;
                border:  1px solid #333333;
            }
            input[type="checkbox"]{
                border-radius: 1px;
                margin-right: 3px;
            }
            label{
                color: #333333c0;
                font-size: 15px;
            }
            span{
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: .5em;
            }
        }

        #viewOffcanvas{
            p{
                color: #333333;
            }
            span{
                display: flex;
                flex-direction: column;
                gap: .5em;
                button{
                    padding-left: 0 !important;
                }
            }
            hr{
                color: gray;
                background-color: gray;
                border-color: gray !important;
            }
            
            label{
                color: #0a60b0c4;
            }
        }
        .offcanvas{
            &-body{

            }
            &-header{
                
                button{
                    margin: 0;
                }
            }
            &-title{
                display: flex;
                flex-grow: 100;
                text-align: center;
                justify-content: center;
                color: #0A60B0;
            }
        }
        #editCompanyOffcanvas{
            .offcanvas{
                &-body{
                    span{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        
                    }
                }
            }
        }
    }
    &-controls{
        display: flex;
        justify-content: space-between;
        gap: .75em;
        input{
            width: 400px;
            border-radius: 1.5em;
            border-color: #0B60B0;
            color: #0B60B0;
            &::placeholder{
                color: #0b60b066;
            }
        }
        #view{
            display: none;
        }
        span{
            display: flex;
            gap: .5em;
        }
    }
    &-table{
        .MuiDataGrid{
            &-columnHeaderTitle{
                color: #0A60B0;
                font-weight: 500;
                font-size: 18px;
                font-family: 'Orkney';
            }
            &-row{
                --rowBorderColor: #0A60B0 !important;
                font-family: 'Orkney';
                &:hover{
                    background-color: #4c8bc51f !important;
                }
            }
           
        }
    }
    .btn{
        &-danger{
            background-color: transparent;
            border-color: transparent;
            color: #333333;
            display: flex;
            align-items: center;
            gap: .25em;
            width: fit-content;
            font-size: 16px;
            &::before{
                content: '';
                width: 20px;
                height: 16px;
                position: relative;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.5' height='20' viewBox='0 0 17.5 20'%3E%3Cpath id='trash-alt' d='M10.469,16.25h.938a.469.469,0,0,0,.469-.469V7.344a.469.469,0,0,0-.469-.469h-.937A.469.469,0,0,0,10,7.344v8.437A.469.469,0,0,0,10.469,16.25ZM16.875,3.125H13.656L12.328.91A1.875,1.875,0,0,0,10.719,0H6.781A1.875,1.875,0,0,0,5.173.91L3.844,3.125H.625A.625.625,0,0,0,0,3.75v.625A.625.625,0,0,0,.625,5H1.25V18.125A1.875,1.875,0,0,0,3.125,20h11.25a1.875,1.875,0,0,0,1.875-1.875V5h.625a.625.625,0,0,0,.625-.625V3.75A.625.625,0,0,0,16.875,3.125ZM6.712,1.989a.234.234,0,0,1,.2-.114h3.672a.234.234,0,0,1,.2.114l.682,1.136H6.031Zm7.663,16.136H3.125V5h11.25ZM6.094,16.25h.938a.469.469,0,0,0,.469-.469V7.344a.469.469,0,0,0-.469-.469H6.094a.469.469,0,0,0-.469.469v8.437A.469.469,0,0,0,6.094,16.25Z' fill='%230a60b0'/%3E%3C/svg%3E%0A");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center bottom;
            }
            &:hover{
                background-color: transparent;
                color: gray;
                border: 1px solid transparent;
                &::before{
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.5' height='20' viewBox='0 0 17.5 20'%3E%3Cpath id='trash-alt' d='M10.469,16.25h.938a.469.469,0,0,0,.469-.469V7.344a.469.469,0,0,0-.469-.469h-.937A.469.469,0,0,0,10,7.344v8.437A.469.469,0,0,0,10.469,16.25ZM16.875,3.125H13.656L12.328.91A1.875,1.875,0,0,0,10.719,0H6.781A1.875,1.875,0,0,0,5.173.91L3.844,3.125H.625A.625.625,0,0,0,0,3.75v.625A.625.625,0,0,0,.625,5H1.25V18.125A1.875,1.875,0,0,0,3.125,20h11.25a1.875,1.875,0,0,0,1.875-1.875V5h.625a.625.625,0,0,0,.625-.625V3.75A.625.625,0,0,0,16.875,3.125ZM6.712,1.989a.234.234,0,0,1,.2-.114h3.672a.234.234,0,0,1,.2.114l.682,1.136H6.031Zm7.663,16.136H3.125V5h11.25ZM6.094,16.25h.938a.469.469,0,0,0,.469-.469V7.344a.469.469,0,0,0-.469-.469H6.094a.469.469,0,0,0-.469.469v8.437A.469.469,0,0,0,6.094,16.25Z' fill='red'/%3E%3C/svg%3E%0A");

                }

            }
        }
        &-save{
            background-color: transparent;
            border-color: transparent;
            color: #333333;
            display: flex;
            gap: .25em;
            align-items: center;
            &::before{
                content: '';
                width: 25px;
                height: 20px;
                position: relative;
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='%23%230A60B0'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z' fill='%230A60B0'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");                    background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        &-edit{
            width: fit-content;
            display: flex;
            &::before{
                content: '';
                width: 25px;
                height: 20px;
                position: relative;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z' fill='%230A60B0'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
            }
        }
        &-add{
            background-color: transparent;
            border-color: transparent;
            color: #333333;
            display: flex;
            gap: .25em;
            width: 100%;
            justify-content: center;
            align-items: center;
            &::before{
                content: '';
                width: 25px;
                height: 20px;
                position: relative;
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z' fill='%230A60B0'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z' fill='%230A60B0'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    #offcanvasImport{
        background-color: #F4F4F4;
        h5{
            text-align: center;
            color: #0A60B0;
            width: 100%;
        }
        .offcanvas{
            
            &-body{
                display: flex;
                flex-direction: column;
               
                .btn{
                    width: 100%;
                }
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 60px;
                    height: 34px;
                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }
                    .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #ccc;
                        -webkit-transition: .4s;
                        transition: .4s;
                        border-radius: 24px;
                    }
                      
                    .slider:before {
                        position: absolute;
                        content: "";
                        height: 26px;
                        width: 26px;
                        left: 4px;
                        bottom: 4px;
                        background-color: white;
                        -webkit-transition: .4s;
                        transition: .4s;
                        border-radius: 100%;
                    }
                      
                    input:checked + .slider {
                        background-color: #2196F3;
                    }
                    input:focus + .slider {
                        box-shadow: 0 0 1px #2196F3;
                    }
                      
                    input:checked + .slider:before {
                        -webkit-transform: translateX(26px);
                        -ms-transform: translateX(26px);
                        transform: translateX(26px);
                    }
                }
                .c-switch{
                    display: flex;
                    gap: .5em;
                    align-items: center;
                    font-size: 16px;
                    font-family: "Orkney";
                }
                #csvInput{
                    &::-webkit-file-upload-button {
                        visibility: hidden;
                    }
                    visibility: hidden;
                    width: 100%;
                    &::before{
                        visibility: visible;
                        width: 100%;
                        cursor: pointer;
                        height: 40px;
                        padding: .2em;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        display: flex;
                        border: 1px dashed #333333;
                        content: 'Adjuntar archivo CSV';
                        font-family: 'Orkney';
                    }
                }
                .loading{
                    display: flex;
                    flex-direction: column;
                    margin: auto;
                    height: 90dvh;
                    align-items: center;
                    width: 100%;
                    justify-content: center;
                }
                .filename{
                    border: 1px dashed gray;
                    padding: .5em;
                    width: 100%;
                    text-align: center;
                }
                strong{
                    padding-top: 1em;
                }
                p{
                    font-family: 'Orkney';
                }
                textarea{
                    width: 100%;
                    height: 60vh;
                    resize: none;
                }
                .report{
                    height: 80dvh;
                    .success, .errors{
                        display: flex;
                        align-items: center;
                        gap: .2em;
                        &::before{
                            position: relative;
                            width: 20px;
                            height: 20px;
                            content: '';
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath id='check-circle' d='M18,8A10,10,0,1,0,28,18,10,10,0,0,0,18,8Zm0,18.71A8.71,8.71,0,1,1,26.71,18,8.709,8.709,0,0,1,18,26.71Zm5.711-11.087-7.277,7.219a.484.484,0,0,1-.684,0l-3.463-3.491a.484.484,0,0,1,0-.684l.344-.341a.484.484,0,0,1,.684,0l2.781,2.8,6.59-6.538a.484.484,0,0,1,.684,0l.341.344a.484.484,0,0,1,0,.684Z' transform='translate(-8 -8)' fill='%233cce36'/%3E%3C/svg%3E%0A");
                            background-size: contain;
                            background-position: center center;
                        }
                    }
                    .errors::before{
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath id='exclamation-circle' d='M18,9.29A8.71,8.71,0,1,1,9.29,18,8.711,8.711,0,0,1,18,9.29M18,8A10,10,0,1,0,28,18,10,10,0,0,0,18,8Zm-.463,4.839h.927a.484.484,0,0,1,.483.5l-.282,6.774a.484.484,0,0,1-.483.464h-.362a.484.484,0,0,1-.483-.464l-.282-6.774a.484.484,0,0,1,.483-.5ZM18,21.387a1.129,1.129,0,1,0,1.129,1.129A1.129,1.129,0,0,0,18,21.387Z' transform='translate(-8 -8)' fill='%23eb970f'/%3E%3C/svg%3E ");
                    }
                }
                
            }
            &-footer{
                padding: 1rem;
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 100%;
                column-gap: .5em;
                &.report{
                    grid-template-columns: 1fr;
                    row-gap: .5em;
                }
            }
        }
    }
}