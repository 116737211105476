@import './styles/button.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn{
 
  padding: .35em 1.3em !important;
  font-weight: 500 !important;
  font-family: 'Orkney';
  &-primary{
    --bs-btn-bg: #0B60B0 !important;
  }
  &-secondary{
    --bs-btn-bg: white !important;
    --bs-btn-color: #707070 !important;
  }
  &-rounded{
    border-radius: 2em !important;
  }

}

hr{
  border: 1px solid #0B60B0 !important;
}

@font-face {
  font-family: 'Orkney';
  src: url('./assets/fonts/Orkney-Bold.eot');
  src: local('./assets/fonts/Orkney Bold'), local('/fonts/Orkney-Bold'),
      url('./assets/fonts/Orkney-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Orkney-Bold.woff2') format('woff2'),
      url('./assets/fonts/Orkney-Bold.woff') format('woff'),
      url('./assets/fonts/Orkney-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orkney';
  src: url('./assets/fonts/Orkney-Medium.eot');
  src: local('./assets/fonts/Orkney Medium'), local('Orkney-Medium'),
      url('./assets/fonts/Orkney-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Orkney-Medium.woff2') format('woff2'),
      url('./assets/fonts/Orkney-Medium.woff') format('woff'),
      url('./assets/fonts/Orkney-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


h1,h2,h3,h4,h5{
  font-family: 'Orkney';
  font-weight: bold;
}
p,label{
  font-family: 'Orkney';
  font-weight: 500;
}