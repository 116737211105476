.menu{
    display: flex;
    flex-direction: column;
    padding: 2em;
    width: -webkit-fill-available;
    height: 80vh;
    justify-content: center;
    gap: .25em;
    a{
        color: #0A60B0;
        text-decoration: none;
        padding: .3em 1em;
        display: flex;
        align-items: center;
        gap: .5em;
        border-radius: 2em;


        transition: all ease-in .2s;
        &:hover{
            text-decoration: none;
            color: white;
            background-color: #0a60b051;
            svg,path{
                fill: white;
            }
        }
        &.-active{
            background-color: #0A60B0;
            color: white;
            svg,path{
                fill: white;
            }
        }
    }

}