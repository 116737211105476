.btn{
    border-radius: .5em;
    font-family: 'Orkney'  !important;
    //display: inline-flex;
    &-primary{
        background-color: #0B60B0;
      
    }
    &-secondary{
        border-color: hsla(0, 0%, 20%, 0.2);
        border-radius: .5em;
    }
    &-back{
        display: inline-flex !important;
        gap: .5em;
        &::before{
            display: flex;
            background-image: url("data:image/svg+xml,%3Csvg fill='%230d6efd' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M222.927 580.115l301.354 328.512c24.354 28.708 20.825 71.724-7.883 96.078s-71.724 20.825-96.078-7.883L19.576 559.963a67.846 67.846 0 01-13.784-20.022 68.03 68.03 0 01-5.977-29.488l.001-.063a68.343 68.343 0 017.265-29.134 68.28 68.28 0 011.384-2.6 67.59 67.59 0 0110.102-13.687L429.966 21.113c25.592-27.611 68.721-29.247 96.331-3.656s29.247 68.721 3.656 96.331L224.088 443.784h730.46c37.647 0 68.166 30.519 68.166 68.166s-30.519 68.166-68.166 68.166H222.927z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
            content: '';
            width: 20px;
            height: 20px;
            background-size:  contain;
            background-repeat: no-repeat;
            position: relative;
            
        }
    }

    &-delete{
        border: 1px solid red !important;
        color: red !important;
    }

    &.-disable{
        cursor: not-allowed;
        opacity: .5;
    }

    
}