.super{
    background-color: #f2f2f28d;;
    &-wrapper{
        background-color: white;
        border-radius: 8% ;
        padding: 2em 5%;
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        h2{
            color: #0A60B0;
            font-weight: 550;
        }
    }
    &-controls{
        display: flex;
        width: 100%;
        justify-content: space-between;
        input{
            width: 300px;
            border-radius: 2em;
            border-color: #0A60B0;
            color: #0A60B0;
            padding: 0 1.5em;
            &::placeholder{
                color: #0a60b075;
            }
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cg id='search-interface-symbol_54481' transform='translate(-0.006)'%3E%3Cg id='_x34__4_' transform='translate(0.006)'%3E%3Cg id='Grupo_25' data-name='Grupo 25' transform='translate(0)'%3E%3Cpath id='Trazado_417' data-name='Trazado 417' d='M13.873,13.238,10.259,9.682A5.717,5.717,0,0,0,11.787,5.8,5.844,5.844,0,0,0,5.9,0,5.844,5.844,0,0,0,.006,5.8a5.844,5.844,0,0,0,5.89,5.8A5.924,5.924,0,0,0,9.6,10.3l3.629,3.571a.458.458,0,0,0,.641,0A.441.441,0,0,0,13.873,13.238ZM5.9,10.7A4.945,4.945,0,0,1,.912,5.8,4.945,4.945,0,0,1,5.9.892,4.945,4.945,0,0,1,10.88,5.8,4.945,4.945,0,0,1,5.9,10.7Z' transform='translate(-0.006)' fill='%230a60b0'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: auto 40%;
            background-position: .5em center;
        }
        button{
            border-radius: 2em;
            background-color: #0A60B0;
            padding: .3em 1.5em;
        }
    }

    &-table{
        .MuiDataGrid{
            &-columnHeaderTitle{
                color: #0A60B0;
                font-weight: 500;
                font-size: 18px;
                font-family: 'Orkney';
            }
            &-row{
                --rowBorderColor: #0A60B0 !important;
                font-family: 'Orkney';
                &:hover{
                    background-color: #4c8bc51f !important;
                }
            }
            &-cell{
                &.active{
                    color: green;
                    font-weight: 600;
                }
                &.block{
                    color: red;
                    font-weight: 600;
                }
            }
        }
    }

    &-edit, &-delete{
        position: relative;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        gap: .5em;
        font-weight: 600;
        &::before{
            width: 25px;
            height: 25px;
            content: '';
            position: relative;
            background-repeat: no-repeat;
            display: flex;
            background-position: center center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath id='pen' d='M19.267,2.2,17.8.732a2.5,2.5,0,0,0-3.536,0l-2.91,2.91L10,5,.5,14.5,0,18.959A.938.938,0,0,0,.935,20a.953.953,0,0,0,.105-.006L5.5,19.5,15,10l1.357-1.357,2.91-2.91a2.5,2.5,0,0,0,0-3.536ZM4.638,17.711,2,18l.294-2.644,9.033-9.033,1.212-1.212,2.349,2.349L13.674,8.674,4.638,17.711Zm13.3-13.3-1.73,1.73L13.862,3.788l1.73-1.73a.624.624,0,0,1,.884,0l1.465,1.465a.626.626,0,0,1,0,.884Z' transform='translate(0.001)' fill='%230a60b0'/%3E%3C/svg%3E%0A");
            background-size: contain;
        }
    }
    &-delete::before{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.5' height='20' viewBox='0 0 17.5 20'%3E%3Cpath id='trash-alt' d='M10.469,16.25h.938a.469.469,0,0,0,.469-.469V7.344a.469.469,0,0,0-.469-.469h-.937A.469.469,0,0,0,10,7.344v8.437A.469.469,0,0,0,10.469,16.25ZM16.875,3.125H13.656L12.328.91A1.875,1.875,0,0,0,10.719,0H6.781A1.875,1.875,0,0,0,5.173.91L3.844,3.125H.625A.625.625,0,0,0,0,3.75v.625A.625.625,0,0,0,.625,5H1.25V18.125A1.875,1.875,0,0,0,3.125,20h11.25a1.875,1.875,0,0,0,1.875-1.875V5h.625a.625.625,0,0,0,.625-.625V3.75A.625.625,0,0,0,16.875,3.125ZM6.712,1.989a.234.234,0,0,1,.2-.114h3.672a.234.234,0,0,1,.2.114l.682,1.136H6.031Zm7.663,16.136H3.125V5h11.25ZM6.094,16.25h.938a.469.469,0,0,0,.469-.469V7.344a.469.469,0,0,0-.469-.469H6.094a.469.469,0,0,0-.469.469v8.437A.469.469,0,0,0,6.094,16.25Z' fill='%230a60b0'/%3E%3C/svg%3E%0A");
    }

    .offcanvas{
        
        padding: 1em;
        &-header{
            justify-content: center;
            h5{
                color: #0A60B0;
                font-weight: 600;
            }
        }
        &-body{
            flex-grow: 0;
            width: 100%;
            padding: 0;
           
            input{
                border-color: #333333;
                border-radius: .5em;
            
            }
            input[type='text'],input[type='email']{
                width: 98%;
                margin: auto;
            }

            
            label{
                color: #3333339d;
                font-weight: 500;
            }
            span{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1em;
            }
            
            strong{
                color: #0A60B0;
            }
        }
    }
    #viewAdminOffcanvas{
        .offcanvas{
            &-body{
                span{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        
    }
    #createAdminOffcanvas{
        .offcanvas{
            &-body{
                flex-direction: column;
                gap: 1em;
                display: flex;
            }
        }
    }
    .c-input{
        display: flex;
        flex-direction: column;
        gap: .25em;
        &.-radio{
            margin-top: 0;
            margin-left: 3px;
            align-items: center;
            flex-direction: row;
        }
    }
    .modal-body{
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
    #deleteModal{
        .modal{
            &-body{
                display: block;
            }
        }
    }
    
}

#viewAdminButton{
    display: none;
}